@charset "utf-8";
@import "setting";
@import "components/reset";
@import "addon/swiper";
@import "addon/slick";
@import "addon/magnific-popup";

/* Animation */
@keyframes border-animation {
  to {
    transform: rotate(360deg);
  }
}
/* 無限ループアニメーション */
@keyframes infinity-loop {
  from {
    transform: translateX(0vw);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes infinity-loop-sp {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-800px);
  }
}

/*----------------------
  common
----------------------*/
a {
  color: $cMain;
  /* text-decoration:none; */
  &:hover {
    color: $cMain;
    text-decoration: none;
    img.hover {
    }
  }
}

.ofh {
  overflow: hidden;
}

.txtUL {
  text-decoration: underline;
}

.fwB {
  font-weight: bold;
}

.caution,
.attention span {
  color: #c00;
}

.list-mark {
  text-indent: -1em;
  padding: 0 0 0 1em;
  overflow: hidden;
}

textarea {
  border-radius: 4px;
  border: solid 1px #d5d5d5;
  padding: 4px 6px;
  margin-bottom: 7px;
}

input {
  &[type="kana_name"],
  &[type="kana_first"],
  &[type="text"],
  &[type="password"],
  &[type="datetime"],
  &[type="datetime-local"],
  &[type="date"],
  &[type="month"],
  &[type="time"],
  &[type="week"],
  &[type="number"],
  &[type="email"],
  &[type="url"],
  &[type="search"],
  &[type="tel"],
  &[type="color"] {
    border-radius: 4px;
    border: solid 1px #d5d5d5;
    padding: 4px 6px;
    margin-bottom: 7px;
  }
}

select[name="prefecture"] {
  border-radius: 4px;
  border: solid 1px #d5d5d5;
  padding: 4px 6px;
  margin-bottom: 7px;
}

input[type="submit"] {
  font: 14px/1.61 "Noto Sans Japanese", "MS PGothic", sans-serif;
  border: none;
}

/*----------------------
  common
----------------------*/

body {
  color: #444444;
  line-height: 1.4;
  font-family: $fg;
  font-size: 14px;
  font-weight: 400;
  background-size: 100% auto;
  background-attachment: fixed;
  a {
    color: #000;
  }
}

.wrapper {
}

.top .wrapper {
  /* background: #fff; */
}

.btn {
  position: relative;
  border: solid 1px;
  width: 290px;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  display: block;
  margin: 0 auto;
  text-decoration: none;
  transition: all 300ms;
  &:before {
    content: "";
    width: 90px;
    height: 1px;
    background: #000;
    position: absolute;
    right: 15px;
    top: calc(50% - 1px);
  }
  &:after {
    content: "";
    width: 10px;
    height: 10px;
    border-left: solid 1px #000;
    position: absolute;
    right: 16px;
    top: calc(50% - 6px);
    transform: rotate(135deg);
  }
  &:hover {
    background: $mainC;
    color: #fff;
    &:before {
      background: #fff;
    }
    &:after {
      border-left: solid 1px #fff;
    }
  }
  &.white {
    color: #fff;
    border: solid 1px #fff;
    background: #000;
    &:before {
      background: #fff;
    }
    &:after {
      border-left: solid 1px #fff;
    }
    &:hover {
      background: #fff;
      color: #000;
      &:before {
        background: #000;
      }
      &:after {
        border-left: solid 1px #000;
      }
    }
  }
}

.attention {
  color: #f00;
}

/*table navi*/

.tablenav {
  color: rgb(65, 65, 65);
  background: white;
  margin: 1em auto 7em;
  line-height: 2em;
  text-align: center;
}
a.page-numbers,
.tablenav .current {
  color: rgb(63, 63, 63);
  border: 1px solid #ccc;
  text-decoration: none;
  font-size: 16px;
  padding-top: 0.5em;
  padding-right: 1em;
  padding-bottom: 0.5em;
  padding-left: 1em;
}
a.page-numbers:hover {
  color: white;
  background-color: rgb(120, 120, 120);
}
.tablenav .current {
  color: white;
  border-color: rgb(63, 63, 63);
  font-weight: bold;
  background-color: #3a3a3a;
}

/*pc-sp common end*/

//PC,SP共通記述
//============================================================
@media screen and (min-width: $w-sp + 1) {
  // pc用スタイルここから
  //============================================================
  @import "pc";
  //============================================================
}
@media screen and (max-width: $w-sp) {
  // sp用スタイルここから
  //============================================================
  @import "sp";

  //============================================================
} //sp用スタイルここまで
//============================================================
