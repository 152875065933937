@charset "utf-8";
.sp {
  display: none;
}
body {
  min-width: 1000px;
  letter-spacing: 0.2px;
  box-sizing: border-box;
  position: relative;
  letter-spacing: 0.1em;

  // HEADER
  .header {
    background: #fff;
    border-top: 4px solid $normalC;
    padding: 30px 20px;
    box-sizing: border-box;
    width: 240px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
    .header-bg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .global-navi {
      width: 100%;
      .logo {
        width: 40px;
        margin: 0 auto;
        a {
          img {
            width: 100%;
          }
        }
      }
      .header-inner {
        nav {
          width: 100%;
          .navi {
            width: 100%;
            li {
              a {
                display: block;
                padding: 15px 0;
                position: relative;
                text-decoration: none;
                &:before {
                  content: "・";
                }
                img {
                }
              }
              &:last-child {
                a {
                  &:after {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .member-btn {
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 100;
    background: $mainC;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    transition: $ease 300ms;
    &:hover {
      background: $colorR;
    }
  }
  // TOP
  // PC VIDEO

  .slide-wrap {
    margin: 0 0 0 200px;
    position: relative;
    .video-wrap {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      /* left: 30%; */
      video {
        width: 100%;
        height: auto;
      }
    }
    .video-cover {
      background: rgba(0, 0, 0, 0.2);
      content: "";
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      position: absolute;
      z-index: 1;
    }
    .first-text {
      text-align: center;
      position: absolute;
      left: 0px;
      width: 100%;
      top: 44%;
      margin: auto;
      color: #fff;
      z-index: 1;
      font-size: 36px;
      font-family: $fm;
      letter-spacing: 0.2em;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
    }
    .secound-text {
      text-align: center;
      position: absolute;
      left: 0px;
      width: 100%;
      top: 55%;
      margin: auto;
      color: #fff;
      z-index: 1;
      font-size: 18px;
      font-family: $fm;
      letter-spacing: 0.2em;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
    }
  }

  .top-content-wrap {
    padding-left: 240px;
    font-family: $fg;

    .title-header {
      text-align: center;
      padding: 0px 0 70px;
      h1 {
        font-size: 36px;
        font-family: $fm;
        font-weight: normal;
        position: relative;
        &:after {
          content: "";
          display: block;
          width: 60px;
          height: 1px;
          background: $mainC;
          margin: 15px auto;
        }
        &:before {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          background: #fff;
          border: 1px solid $mainC;
          bottom: -4px;
          left: calc(50% - 4px);
          transform: rotate(45deg);
          box-sizing: border-box;
        }
      }
      h2 {
        font-size: 12px;
        font-weight: normal;
      }
    }
    .catch {
      h1 {
        text-align: center;
        padding: 30px;
        background: #fefefe;
        font-size: 38px;
        color: $mainC;
        font-weight: bold;
        border-top: 1px solid $mainC;
        border-bottom: 1px solid $mainC;
        box-sizing: border-box;
        position: relative;
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 4px;
          width: calc(100% - 60px);
          height: 1px;
          left: 30px;
          background: $mainC;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 4px;
          width: calc(100% - 60px);
          height: 1px;
          left: 30px;
          background: $mainC;
        }
        img {
          display: block;
          width: 150px;
          margin: 0 auto;
        }
      }
      margin-bottom: 30px;
      padding: 0 50px;
    }
    .catch-text {
      text-align: center;
      background: #fefefe;
      font-size: 24px;
      color: $mainC;
      font-weight: bold;
      box-sizing: border-box;
      margin-bottom: 20px;
    }
    .section-wrap {
      padding: 70px 0;
      .inner {
      }
    }
    .enduser-wrap {
      .inner {
        .merit-text {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 60px;
          li {
            width: 50%;
            box-sizing: border-box;
            padding: 15px;
            margin-bottom: 30px;
            opacity: 0;
            transition: $ease 300ms;
            &.loaded {
              opacity: 1;
            }
            .photo {
              margin-bottom: 15px;
              img {
                width: 100%;
              }
            }
            .detail {
              padding: 0 10px;
              .title {
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 10px;
                color: $colorR;
              }
              .detail-txt {
                margin-bottom: 30px;
              }
              .btn {
                width: 100%;
              }
            }
          }
        }
        .kameiten-list {
          .search-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            opacity: 0;
            transition: $ease 300ms;
            &.loaded {
              opacity: 1;
            }
            .photo {
              margin-bottom: 15px;
              width: calc(100% - 250px);
              text-align: center;
              img {
                max-width: 100%;
              }
            }
            .search-navi {
              width: 250px;
              padding-left: 30px;
              box-sizing: border-box;
              li {
                margin-bottom: 15px;
                a {
                  display: block;
                  text-align: center;
                  height: 30px;
                  line-height: 30px;
                  border-radius: 15px;
                  color: $colorB;
                  padding: 0 10px;
                  box-sizing: border-box;
                  border: 1px solid;
                  text-decoration: none;
                  transition: $ease 300ms;
                  &:hover {
                    background: $colorB;
                    color: #fff;
                  }
                }
              }
            }
          }

          .title {
            text-align: center;
            font-size: 36px;
            color: $mainC;
            margin-bottom: 15px;
          }
          .detail-text {
            text-align: center;
            margin-bottom: 30px;
          }
        }
      }
    }
    .topics-wrap {
      .inner {
        .topics-list {
          li {
            border-bottom: 1px solid #eee;
            opacity: 0;
            transition: $ease 300ms;
            &.loaded {
              opacity: 1;
            }
            a {
              display: flex;
              align-items: center;
              text-decoration: none;
              padding: 10px;
              color: $normalC;
              .date {
                font-size: 12px;
                width: 80px;
                margin-right: 15px;
              }
              .cat {
                font-size: 12px;
                width: 90px;
                margin-right: 15px;
                background: $colorG;
                color: #fff;
                text-align: center;
              }
              .title {
              }
            }
          }
        }
      }
    }
    .about-wrap {
      .infinity-slide {
        display: flex;
        width: auto;
        overflow: hidden;
        transition: opacity $easeInSine 1000ms;
        opacity: 0;
        height: 240px;
        margin-bottom: 50px;
        &.loaded {
          opacity: 1;
        }
        .slide {
          animation: infinity-loop 70s infinite linear 1s both;
          display: flex;
          height: 240px;
          position: relative;
          img {
            height: 100%;
            width: auto;
          }
        }
      }
      .map {
        margin-bottom: 50px;
        opacity: 0;
        transition: $ease 300ms;
        &.loaded {
          opacity: 1;
        }
        iframe {
          width: 100%;
          height: 550px;
        }
      }
    }
  }

  section {
    .inner {
      margin: 0 auto;
      position: relative;
      box-sizing: border-box;
      padding: 0 50px;
    }
  }

  // FOOTER
  .page-top-wrap {
    position: fixed;
    width: 40px;
    height: 60px;
    bottom: 0;
    right: 0;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
    .page-top {
      color: #000;
      display: block;
      border-top: solid 1px;
      border-left: solid 1px;
      transform: rotate(45deg) translate3d(10px, 10px, 0);
      width: 100%;
      height: 20px;
    }
  }
  .footer {
    background: $normalC;
    padding: 50px 20px 20px 240px;
    box-sizing: border-box;
    color: #fff;
    .footer-inner {
      padding: 0 50px;
      a {
        text-decoration: none;
        color: #fff;
      }
      .footer-up {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        .footer-main-nav {
          width: 300px;
          .navi {
            li {
              list-style: square;
              a {
              }
            }
          }
        }
        .ohter-banner-list {
          width: calc(100% - 300px);
          display: flex;
          justify-content: space-between;

          li {
            width: 50%;
            text-align: center;
            padding: 0 15px;
            box-sizing: border-box;
            a {
              display: block;
              position: relative;
              color: $normalC;
              img {
                width: 100%;
              }
              .title {
                position: absolute;
                bottom: 0;
                left: 0;
                background: rgba(255, 255, 255, 0.8);
                font-size: 12px;
                padding: 10px;
                box-sizing: border-box;
                width: 100%;
              }
            }
          }
        }
      }
      .footer-down {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        .footer-search-navi {
          width: calc(100% - 300px);
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          li {
            width: 50%;
            padding-right: 15px;
            box-sizing: border-box;
            margin-bottom: 10px;
            a {
            }
          }
        }
        .about {
          width: 300px;
          title {
          }
          address {
          }
        }
      }
    }
    .copy-wrap {
      text-align: center;
      .copy {
        text-align: center;
        color: #918989;
      }
    }
  }

  // SUB PAGE
  &.sub-page {
    .page {
      padding-top: 80px;
    }
    .sub-main-title {
      text-align: center;
      margin-bottom: 60px;
    }
    section {
      padding: 80px 0;
      .title-header {
        margin-bottom: 80px;
        text-align: center;
        position: relative;
        color: #000;
        font-size: 38px;
        letter-spacing: 0.1em;
        font-weight: bold;
        transition: all 300ms 500ms;
        transform: translateY(50px);
        &:after {
          content: "";
          width: 40px;
          height: 1px;
          margin: 40px auto 0;
          background: #979797;
          display: block;
        }
      }
      .inner {
        width: 1140px;
        margin: 0 auto;
      }
    }
    /* SALON PAGE */
    .salon-content-wrap {
      .slider-wrap {
        box-sizing: border-box;
        .inner {
          margin: 0 auto;
          width: 1140px;
        }
        .slick-arrow,
        .slick-dots {
          display: none !important;
        }
        .slider-for {
          img {
            width: 100%;
            height: auto;
          }
        }
        .slider-nav {
          li {
            width: 100px;
            padding: 15px 10px;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      .salon-info-wrap {
        // background: #fffef7;
        .inner {
          .salon-info-header {
            display: flex;
            margin-bottom: 50px;
            h2 {
              width: 300px;
              padding-left: 30px;
              box-sizing: border-box;
              color: #bd7e7a;
              font-size: 17px;
            }
            .detail {
              width: calc(100% - 200px);
              p {
                color: #767676;
              }
            }
          }
          .concept-area {
            display: flex;
            li {
              width: calc(100% / 3);
              padding: 0 20px;
              box-sizing: border-box;
              text-align: center;
              .detail {
                .photo {
                  width: 270px;
                  height: 270px;
                  margin: 0 auto 20px;
                  border-radius: 135px;
                  overflow: hidden;
                  img {
                    width: 100%;
                    height: auto;
                  }
                }
                .title {
                  font-size: 18px;
                  font-weight: bold;
                  margin-bottom: 10px;
                }
                .detail-text {
                }
              }
            }
          }
        }
      }
      .salon-tel-reserve-wrap {
        background: #ebebeb;
        .inner {
          display: flex;
          justify-content: space-around;
          align-items: center;
          .tel-area {
            /* display:flex; */
            width: 50%;
            text-align: center;
            .sub-title {
            }
            .phone-number {
              font-size: 18px;
              font-weight: bold;
            }
          }
          .reserve-wrap {
            width: 50%;
            text-align: center;
          }
          .reserve-btn {
            display: block;
            width: 257px;
            background: #2d2d2d;
            padding: 10px;
            box-sizing: border-box;
            color: #fff;
            text-align: center;
            position: relative;
            margin: 0 auto;
            &:before {
              content: "";
              position: absolute;
              left: 5px;
              top: 5px;
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              border: solid 1px;
            }
          }
        }
      }

      .salon-calender-wrap {
        .inner {
          display: flex;
          .shop-info {
            width: 50%;
            text-align: center;
            .salon-hour {
              font-size: 18px;
              margin-bottom: 20px;
              font-weight: bold;
              &:after {
                content: "";
                width: 15px;
                height: 23px;
                display: block;
                margin: 30px auto 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-top: 23px solid #f8e71c;
              }
            }
            .info-table {
              font-size: 16px;
              width: 300px;
              margin: 0 auto;
              th {
                color: #979797;
                padding-bottom: 10px;
                text-align: left;
              }
              td {
                padding-left: 40px;
                text-shadow: 0px 3px 3px #a2a2a2;
              }
            }
          }
          .shop-calender {
            width: 50%;
            .calender-text {
              line-height: 20px;
              span {
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 10px;
                overflow: hidden;
                background: #000;
                transform: translateY(4px);
              }
            }
          }
        }
      }
      .access-wrap {
        .title-header {
          padding: 15px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 50px;
        }
        .parking {
          margin: 50px auto;
          text-align: center;
          font-size: 16px;
          font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN",
            "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
          color: #000;
          span {
            display: inline-block;
            background: #5e3820;
            color: #fff;
            padding: 3px 15px;
          }
        }
        .map {
          margin-bottom: 50px;
          iframe {
            width: 100%;
            height: 550px;
          }
        }
        .inner {
          width: 1140px;
          padding-left: 250px;
          box-sizing: border-box;
          p {
            font-size: 15px;
            line-height: 1.8;
          }
        }
      }
    }
    // STYLE PAGE
    .style-content-wtap {
      .style-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        .list {
          width: calc(50% - 20px);
          border: double 3px #ccc;
          padding: 10px;
          margin: 10px;
          box-sizing: border-box;
          img {
            width: 100px;
            height: auto;
          }
          ul {
            overflow: hidden;
            li {
              float: left;
              width: 25%;
              padding: 10px;
              box-sizing: border-box;
              img {
                width: 100%;
              }
              &.img-1 {
                width: 50%;
              }
            }
          }
        }
      }
    }
    // MENU PAGE
    .menu-content-wrap {
      .menu-wrap {
        .inner {
          .menu-box {
            display: flex;
            flex-wrap: wrap;
            .menu-list {
              width: 50%;
              padding: 0 20px;
              box-sizing: border-box;
              li {
                margin-bottom: 30px;
                .detail {
                  .menu-detail {
                    margin-bottom: 10px;
                    li {
                      border-bottom: 1px solid #000;
                      position: relative;
                      padding: 15px 0;
                      margin-bottom: 0;
                      .menu-title {
                      }
                      .sub-menu-title {
                        padding-left: 15px;
                      }
                      span {
                        min-width: 200px;
                        display: inline-block;
                      }
                      .price {
                        position: absolute;
                        right: 5px;
                        top: 15px;
                      }
                    }
                  }
                  .supplement {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
    // BLOG PAGE
    .blog-content-wrap {
      .inner {
        overflow: hidden;
      }
      article {
        &.entry {
          .cnts {
            /*padding: 30px 0px 25px;*/
            clear: both;
            position: relative;
            margin-bottom: 90px;
            .care,
            .style,
            .products,
            .blog {
              margin: 0 0 10px 0;
              position: absolute;
              left: 0;
              top: 50px;
              padding: 0 10px;
            }
          }
          h1 {
            font-size: 24px;
            text-align: left;
            font-weight: bold;
            /* border-bottom: 1px #BBBBBB solid; */
            color: #666666;
            margin-top: 30px;
            margin-right: 0;
            margin-bottom: 60px;
            /* margin-left: 100px; */
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 30px;
          }
          .mainImg {
            margin: 0 0 30px 0;
          }
          .txt {
            margin: 0 0 60px 0;
            color: #666666;
            img {
              max-width: 550px;
              height: auto;
            }
            p {
              margin: 0 0 10px;
            }
          }
        }
      }
      .entries {
        float: left;
        padding: 0 0 60px 30px;
        width: 750px;
        .entry {
          overflow: hidden;
          border-bottom: 1px #aaaaaa solid;
          padding: 10px 0 20px 0;
          position: relative;
          margin: 0 0 30px 0;
          .entryInfo {
            position: absolute;
            bottom: 0;
            border-top: 1px solid #ccc;
            li {
              float: left;
              padding: 10px;
              height: 100%;
              font-size: 10px;
            }
            .author {
              border-right: 1px solid #ccc;
            }
            .date {
              float: right;
              border-left: 1px solid #ccc;
            }
          }
          section {
          }

          .entry_arrow {
            float: right;
            position: relative;
            top: 5px;
            width: 140px;
          }
          .style {
            background: #00b2b2;
            color: #fff;
            line-height: 30px;
            padding: 10px 15px 6px;
          }
          .care {
            background: #ffcc99;
            color: #fff;
            line-height: 30px;
            padding: 10px 25px 6px;
          }
          .products {
            background: #ff9999;
            color: #fff;
            line-height: 30px;
          }
          h2 {
            margin: 0 0 30px 0;
          }
          h3 {
            margin: 0 0 30px 0;
            font-size: 17px;
            font-weight: bold;
            background: #f8f8f8;
            padding: 15px 25px;
            width: 650px;
          }
          h4 {
            font-size: 18px;
            font-weight: bold;
            margin: 10px 0 20px 0;
          }
          .entry_arrow {
            img {
              position: absolute;
              right: 30px;
              top: -4px;
              transition: all 0.3s ease 0s;
              z-index: 3;
            }
          }
          a {
            &:hover {
              background: #f8f8f8;
              z-index: 999;
              overflow: hidden;
              transition: all 0.3s ease 0s;
              .entry_arrow img {
                right: 20px;
              }
            }
          }
          .txtBox {
            width: 430px;
            float: left;
            .expert {
              /* border-bottom: 1px #F0F0E1 solid; */
              margin: 0 0 10px 0;
              padding: 0 0 10px 0;
            }
            .date-cat {
              .date {
              }
              .cat {
                background: #f8b551;
                color: #fff;
                display: inline-block;
                padding: 3px 5px;
                margin-left: 10px;
              }
            }
          }
          a {
            display: block;
            color: #868686;
            text-decoration: none;
            padding: 10px 0px;
            box-sizing: border-box;
          }
          figure {
            overflow: hidden;
            width: 250px;
            height: 200px;
            float: left;
            margin: 0 30px 0 0;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
        .cat_outer {
          height: 60px;
          overflow: hidden;
        }
        .blog {
          background: #dfdfd0;
          color: #fff;
          line-height: 30px;
          padding: 10px 25px 6px;
        }

        .category {
          ul {
            padding: 10px 0;
            li {
              margin: 0 0 10px;
              padding: 0 0 10px 0;
              /* border-bottom: 1px #666 solid; */
              a {
                display: block;
                padding: 0 0 0 15px;
                height: 20px;
                line-height: 20px;
                color: #8c8c8c;
                text-decoration: none;
                margin-bottom: 20px;
              }
            }
          }
        }

        .tag {
          border: 1px solid #666;
          margin: 15px 0 10px;
          padding: 15px 0 5px 55px;
        }
        .tag02 {
          border: 1px solid #666;
          margin: 15px 0 10px;
          padding: 15px 0 5px 23px;
        }
      }
      .catename {
        padding-top: 5px;
        padding-right: 10px;
        padding-bottom: 5px;
        padding-left: 10px;
        background-color: #f8b551;
        color: #ffffff;
        display: inline-block;
        margin-left: 10px;
      }
      .recommend-main {
        float: left;
        padding: 0 0 60px 0px;
        width: 750px;
      }
      .recommend-kiji {
        float: left;
        padding: 0 0 60px 0px;
        width: 650px;
      }
      .related-posts {
        h2 {
          text-align: left;
          position: relative;
          width: 270px;
          font-size: 20px;
          color: #8c8c8c;
          padding-left: 20px;
          margin-bottom: 40px;
          &:after {
            content: "";
            background: #cfa972;
            display: block;
            width: 100%;
            height: 2px;
            border: solid 1px #eee;
            position: absolute;
            left: 0;
            bottom: -10px;
          }
        }
        ul {
          li {
            margin-bottom: 20px;
            a {
              display: flex;
              justify-content: left;
              align-items: flex-start;
              width: 100%;
              text-decoration: none;
              .thumb {
                width: 170px;
                height: 170px;
                overflow: hidden;
                img {
                  width: 100%;
                  height: auto;
                }
              }
              .detail {
                width: calc(100% - 80px);
                padding-left: 10px;
                display: block;
                p {
                  display: block;
                  width: 100%;
                }
                .title {
                  font-size: 20px;
                  margin-bottom: 10px;
                }
                .text {
                }
              }
            }
          }
        }
      }
    }
  }
}
