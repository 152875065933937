@charset "utf-8";
.pc {
  display: none;
}
body {
}
.header {
  padding: 15px 10px 15px;
  text-align: center;
  position: fixed;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
  background: rgba(216, 216, 216, 0.6);
  .header-bg {
    position: absolute;
    width: 100%;
    height: 200%;
    top: -5px;
    left: 0;
    z-index: -1;
    path {
      fill: #fff;
      stroke: #f9dcea;
      stroke-width: 1px;
    }
  }
  .logo {
    padding-top: 15px;
    img {
      width: 150px;
    }
  }
  .menu-trigger {
    overflow: hidden;
    position: absolute;
    top: 25px;
    right: 10px;
    width: 26px;
    height: 26px;
    z-index: 100;
    span {
      position: absolute;
      right: 0px;
      width: 100%;
      height: 2px;
      background: rgb(58, 58, 58);
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      right: 0px;
      width: 100%;
      height: 2px;
      background: rgb(58, 58, 58);
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
    }
    &:before {
      top: 6px;
    }
    &:after {
      top: 12px;
    }
  }
}
.menu-show .header .menu-trigger {
  z-index: 5001;
  span {
    transform: rotate(45deg) translate(7px, 10px);
    -webkit-transform: rotate(45deg) translate(7px, 10px);
  }
  &:before {
    transform: translate(-100%);
    -webkit-transform: translate(-100%);
  }
  &:after {
    transform: rotate(-45deg) translate(-2px, -1px);
    -webkit-transform: rotate(-45deg) translate(-2px, -1px);
  }
}
.header .gnav {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  padding: 80px 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: -1;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  transform: translateY(-100%);
}
.menu-show .header .gnav {
  z-index: 5000;
  opacity: 1;
  transform: translateY(0%);
}
.header {
  .reserve-btn {
    position: absolute;
    width: 130px;
    right: 60px;
    top: 10px;
    height: 40px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $cMain;
      color: #fff;
      padding: 5px;
      text-align: center;
      border-radius: 10px;
      box-sizing: border-box;
      font-size: 12px;
      text-decoration: none;
    }
    .icon {
      display: inline-block;
      margin-right: 5px;
      width: 20px;
      svg {
        width: 100%;
        height: auto !important;
        path {
          fill: #fff;
        }
      }
    }
  }
  .gnav {
    a {
      display: flex;
      padding: 10px;
      color: $cMain;
      font-size: 16px;
      font-weight: normal;
      text-decoration: none;
      justify-content: flex-start;
      align-items: center;
      border-bottom: solid 1px #eee;
    }
    ul li a .icon svg {
      display: inline-block;
      width: auto;
      height: 30px;
      margin: 0 20px 0 0;
      path,
      rect {
        fill: $cMain;
      }
    }
    &:nth-child(1) {
      a {
        position: relative;
        .icon {
          svg {
            height: 60px;
          }
        }
      }
    }
    &:nth-child(2) {
      a {
        .icon {
          svg {
            height: 60px;
          }
        }
      }
    }
    &:nth-child(3) {
      a {
        .icon {
          svg {
            height: 60px;
          }
        }
      }
    }
    &:nth-child(4) {
    }
    &:nth-child(5) {
      a {
        .icon {
          svg {
            height: 60px;
          }
        }
      }
    }
    a {
      position: relative;
      display: block;
      transition: all 300ms;
      text-decoration: none;
      padding: 10px 0px;
      background: transparent;
      font-size: 17px;
      .en {
        font-family: "futura PT";
        transition: all 300ms;
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
      }
      .jp {
        display: block;
        font-size: 11px;
        color: #333;
        margin: 0;
        padding: 0;
      }
      &:hover {
        color: $cMain;
        text-decoration: none;
      }
      .icon {
        display: block;
        transform: translateY(0);
        transition: all 300ms;
        svg {
          display: block;
          width: auto;
          height: 40px;
          margin: 0 auto;
          path,
          rect {
            fill: #b2b3b5;
            color: #b2b3b5;
          }
        }
      }
      &:hover .icon {
        transform: translateY(-20px);
      }

      &:hover .icon {
        svg {
          path,
          rect {
            fill: $cMain;
          }
        }
        transform: translateY(-10px);
      }
    }
  }
  h1 {
    display: flex;
    align-items: center;
    img {
      width: 120px;
      height: auto;
    }
    span {
      font-size: 10px;
      display: inline-block;
      margin: 0 0 20px 10px;
    }
  }
  &.black .menu-trigger {
    span,
    &:before,
    &:after {
    }
  }
}

.header .gnav ul {
  text-align: center;
  /* font-family: "a-otf-ud-reimin-pr6n",sans-serif; */
  padding: 20px;
  box-sizing: border-box;
  li {
    letter-spacing: 0.2em;
  }
}

// TOP
.slide-wrap {
  padding:70px 0 0;
  .first-text{
  text-align: center;
  position: absolute;
  left:0px;
  width:100%;
  top: 22%;
  margin:auto;
  color: #fff;
  z-index: 1;
  font-size: 18px;
  letter-spacing:0.2em;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.6);
  }
  .secound-text{
    text-align: center;
    position: absolute;
    left:0px;
    width:100%;
    top: 28%;
    margin:auto;
    color: #fff;
    z-index: 1;
    font-size: 12px;
    letter-spacing:0.2em;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.6);
    }
  .top-slider {
    .swiper-container {
      width: 100%;
      .swiper-wrapper {
        .swiper-slide {
          img {
            width: 100% !important;
          }
        }
      }
    }
  }
}
.top-content-wrap .section-wrap {
  padding: 50px 0;
}
.top-content-wrap {
  .title-header {
    text-align: center;
    margin-bottom: 30px;
  }
  .topic-wrap {
    .inner {
      margin: 0 auto;
      .title-header {
        img {
        }
      }
      .topics-list {
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        li {
          width: 50%;
          padding: 10px;
          box-sizing: border-box;
          a {
            text-decoration: none;
            .photo {
              margin-bottom: 5px;
              img {
                width: 100%;
                height: auto;
              }
            }
            .detail {
              .date {
                margin-bottom: 5px;
              }
              .title {
                margin-bottom: 20px;
                font-weight: bold;
                font-size: 16px;
              }
              .cat {
                span {
                  display: inline-block;
                  background: #d8d8d8;
                  color: #595959;
                  padding: 3px 10px;
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  .style-wrap {
    background: #000;
    .title-header {
    }
    .inner {
      .swiper-outer {
        width: 100%;
        margin: 0 auto;
        position: relative;
        .swiper-container {
          margin-bottom: 0 auto 60px;
          width: 280px;
          .swiper-wrapper {
            text-align: center;
            overflow: visible;

            .swiper-slide {
              margin: 0 auto;
              img {
                width: 100%;
              }
            }
          }
        }

        .swiper-button-prev {
        }
        .swiper-button-next {
        }
      }
    }
  }
  .instagram-wrap {
    background: #f4f4f4;
    padding: 30px 0px;
    box-sizing: border-box;
    .inner {
      .title-header {
        text-align: right;
        margin-bottom: 20px;
        img {
          width: 200px;
        }
      }
    }
  }
  .salon-wrap {
    background: #f4f4f4;
    padding: 0 0 10px;
    .salon-slider {
      margin-bottom: 30px;
      li {
        img {
          height: 300px;
        }
      }
    }
    .inner {
      .salon-detail-wrap {
        z-index: 1;
        .left {
          box-sizing: border-box;
          .salon-detail {
            background: #fff;
            padding: 10px;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            dt {
              width: 30%;
              margin-bottom: 15px;
            }
            dd {
              width: 70%;
              margin-bottom: 15px;
              .tel {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 30px;
              }
            }
          }
          .btn {
            width: 100%;
          }
        }
        .right {
          padding: 30px 0;
          text-align: center;
          p {
            font-size: 20px;

            font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN",
              "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
            color: #000;
          }
        }
      }
    }
  }
  .staff-wrap {
    .title-header {
      text-align: center;
      margin-bottom: 20px;
      &:after {
        content: "";
        background: #ccc;
        width: 60px;
        height: 1px;
        display: block;
        margin: 15px auto 0px;
      }
    }
    .inner {
      .staff-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          width: 50%;
          padding: 10px;
          box-sizing: border-box;
          margin-bottom: 20px;
          a {
            text-decoration: none;
            .photo {
              margin-bottom: 10px;
              img {
                width: 100%;
                height: auto;
              }
            }
            .yaku {
              color: #767676;
              font-size: 15px;
              margin-bottom: 10px;
            }
            .name {
              font-size: 20px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .access-wrap {
    .title-header {
      background: #d8d8d8;
      color: #918989;
      padding: 10px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
    }
    .parking {
      margin: 20px auto;
      text-align: center;
      font-size: 16px;
      font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN",
        "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
      color: #000;
      span {
        display: inline-block;
        background: #5e3820;
        color: #fff;
        padding: 3px 15px;
      }
    }
    .map {
      margin-bottom: 20px;
      iframe {
        width: 100%;
        height: 350px;
      }
    }
    .inner {
      box-sizing: border-box;
      p {
        font-size: 15px;
        line-height: 1.8;
      }
    }
  }
}

section {
  .inner {
    width: 96%;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
  }
}

// FOOTER
.page-top-wrap {
  position: fixed;
  width: 40px;
  height: 60px;
  bottom: 40px;
  right: 0;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
  .page-top {
    color: #000;
    display: block;
    border-top: solid 1px;
    border-left: solid 1px;
    transform: rotate(45deg) translate3d(10px, 10px, 0);
    width: 100%;
    height: 20px;
  }
}
.footer {
  background: #dbdbdb;
  padding: 20px;
  box-sizing: border-box;
  .copy-wrap {
    text-align: center;
    .copy {
      text-align: center;
      color: #918989;
    }
  }
}
// reserve-wrap
.reserve-wrap {
  background: #e3e3e3;
  padding: 20px 0;
  .inner {
    .title {
      text-align: center;
      font-size: 17px;
      font-weight: bold;
      &:after {
        content: "";
        display: block;
        width: 20px;
        border-top: solid 20px #000;
        border-left: solid 20px transparent;
        border-right: solid 20px transparent;
        margin: 15px auto 30px;
      }
    }
    .reserve-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        padding: 0 10px;
        width: 50%;
        box-sizing: border-box;
        a {
          display: block;
          background: #fff;
          color: #000;
          text-align: center;
          font-weight: bold;
          height: 50px;
          line-height: 50px;
          width: 100%;
        }
      }
    }
  }
}
// SUB PAGE
body {
  &.sub-page {
    .page {
      padding-top: 80px;
    }
    .sub-main-title {
      text-align: center;
      margin-bottom: 30px;
    }
    section {
      padding: 30px 0;
      .title-header {
        margin-bottom: 30px;
        text-align: center;
        position: relative;
        color: #000;
        font-size: 26px;
        letter-spacing: 0.1em;
        font-weight: bold;
        &:after {
          content: "";
          width: 40px;
          height: 1px;
          margin: 40px auto 0;
          background: #979797;
          display: block;
        }
      }
      .inner {
        width: 96%;
        margin: 0 auto;
      }
    }
    /* SALON PAGE */
    .salon-content-wrap {
      .slider-wrap {
        box-sizing: border-box;

        .slick-arrow,
        .slick-dots {
          display: none !important;
        }
        .slider-for {
          img {
            width: 100%;
            height: auto;
          }
        }
        .slider-nav {
          li {
            width: 100px;
            padding: 15px 10px;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      .salon-info-wrap {
        // background: #fffef7;
        .inner {
          .salon-info-header {
            margin-bottom: 50px;
            h2 {
              box-sizing: border-box;
              color: #bd7e7a;
              font-size: 17px;
            }
            .detail {
              p {
                color: #767676;
              }
            }
          }
          .concept-area {
            li {
              padding: 0 20px;
              box-sizing: border-box;
              text-align: center;
              .detail {
                .photo {
                  width: 270px;
                  height: 270px;
                  margin: 0 auto 20px;
                  border-radius: 135px;
                  overflow: hidden;
                  img {
                    width: 100%;
                    height: auto;
                  }
                }
                .title {
                  font-size: 18px;
                  font-weight: bold;
                  margin-bottom: 10px;
                }
                .detail-text {
                }
              }
            }
          }
        }
      }
      .salon-tel-reserve-wrap {
        background: #ebebeb;
        .inner {
          .tel-area {
            text-align: center;
            .sub-title {
            }
            .phone-number {
              font-size: 16px;
              font-weight: bold;
            }
          }
          .reserve-wrap {
            text-align: center;
          }
          .reserve-btn {
            display: block;
            width: 257px;
            background: #2d2d2d;
            padding: 10px;
            box-sizing: border-box;
            color: #fff;
            text-align: center;
            position: relative;
            margin: 0 auto;
            &:before {
              content: "";
              position: absolute;
              left: 5px;
              top: 5px;
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              border: solid 1px;
            }
          }
        }
      }

      .salon-calender-wrap {
        .inner {
          .shop-info {
            text-align: center;
            .salon-hour {
              font-size: 18px;
              margin-bottom: 20px;
              font-weight: bold;
              &:after {
                content: "";
                width: 15px;
                height: 23px;
                display: block;
                margin: 30px auto 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-top: 23px solid #f8e71c;
              }
            }
            .info-table {
              font-size: 16px;
              width: 100%;
              margin: 0 auto;
              th {
                color: #979797;
                padding-bottom: 10px;
                text-align: left;
              }
              td {
                padding-left: 40px;
                text-shadow: 0px 3px 3px #a2a2a2;
              }
            }
          }
          .shop-calender {
            .calender-text {
              line-height: 20px;
              span {
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 10px;
                overflow: hidden;
                background: #000;
                transform: translateY(4px);
              }
            }
          }
        }
      }
      .access-wrap {
        .title-header {
          padding: 10px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 30px;
        }
        .parking {
          margin: 30px auto;
          text-align: center;
          font-size: 16px;
          font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN",
            "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
          color: #000;
          span {
            display: inline-block;
            background: #5e3820;
            color: #fff;
            padding: 3px 15px;
          }
        }
        .map {
          margin-bottom: 30px;
          iframe {
            width: 100%;
            height: 350px;
          }
        }
        .inner {
          box-sizing: border-box;
          p {
            font-size: 15px;
            line-height: 1.8;
          }
        }
      }
    }
    // STYLE PAGE
    .style-content-wtap {
      .style-box {
        .list {
          border: double 3px #ccc;
          padding: 10px;
          margin: 10px;
          box-sizing: border-box;
          img {
            width: 100px;
            height: auto;
          }
          ul {
            overflow: hidden;
            li {
              float: left;
              width: 25%;
              padding: 10px;
              box-sizing: border-box;
              img {
                width: 100%;
              }
              &.img-1 {
                width: 50%;
              }
            }
          }
        }
      }
    }
    // MENU PAGE
    .menu-content-wrap {
      .menu-wrap {
        .inner {
          .menu-box {
            .menu-list {
              padding: 0 20px;
              box-sizing: border-box;
              li {
                margin-bottom: 20px;
                .detail {
                  .menu-detail {
                    margin-bottom: 10px;
                    li {
                      border-bottom: 1px solid #000;
                      position: relative;
                      padding: 15px 0;
                      margin-bottom: 0;
                      .menu-title {
                      }
                      .sub-menu-title {
                        padding-left: 15px;
                      }
                      span {
                        min-width: 180px;
                        display: inline-block;
                      }
                      .price {
                        position: absolute;
                        right: 5px;
                        top: 15px;
                      }
                    }
                  }
                  .supplement {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
    // BLOG PAGE
    .blog-content-wrap {
      .inner {
        overflow: hidden;
      }
      article {
        &.entry {
          .cnts {
            /*padding: 30px 0px 25px;*/
            clear: both;
            position: relative;
            margin-bottom: 30px;
            .care,
            .style,
            .products,
            .blog {
              margin: 0 0 10px 0;
              position: absolute;
              left: 0;
              top: 50px;
              padding: 0 10px;
            }
          }
          h1 {
            font-size: 20px;
            text-align: left;
            font-weight: bold;
            /* border-bottom: 1px #BBBBBB solid; */
            color: #666666;
            margin-top: 15px;
            margin-right: 0;
            margin-bottom: 30px;
            /* margin-left: 100px; */
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 315px;
          }
          .mainImg {
            margin: 0 0 15px 0;
          }
          .txt {
            margin: 0 0 15px 0;
            color: #666666;
            img {
              max-width: 100%;
              height: auto;
            }
            p {
              margin: 0 0 10px;
            }
          }
        }
      }
      .entries {
        padding: 0 0 30px 0px;
        .entry {
          overflow: hidden;
          border-bottom: 1px #aaaaaa solid;
          padding: 10px 0 20px 0;
          position: relative;
          margin: 0 0 30px 0;
          .entryInfo {
            position: absolute;
            bottom: 0;
            border-top: 1px solid #ccc;
            li {
              float: left;
              padding: 10px;
              height: 100%;
              font-size: 10px;
            }
            .author {
              border-right: 1px solid #ccc;
            }
            .date {
              float: right;
              border-left: 1px solid #ccc;
            }
          }
          section {
          }

          .entry_arrow {
            float: right;
            position: relative;
            top: 5px;
            width: 140px;
          }
          .style {
            background: #00b2b2;
            color: #fff;
            line-height: 30px;
            padding: 10px 15px 6px;
          }
          .care {
            background: #ffcc99;
            color: #fff;
            line-height: 30px;
            padding: 10px 25px 6px;
          }
          .products {
            background: #ff9999;
            color: #fff;
            line-height: 30px;
          }
          h2 {
            margin: 0 0 30px 0;
          }
          h3 {
            margin: 0 0 30px 0;
            font-size: 16px;
            font-weight: bold;
            background: #f8f8f8;
            padding: 15px 25px;
          }
          h4 {
            font-weight: bold;
            margin: 10px 0 20px 0;
          }
          .entry_arrow {
            img {
              position: absolute;
              right: 30px;
              top: -4px;
              transition: all 0.3s ease 0s;
              z-index: 3;
            }
          }
          a {
            &:hover {
              background: #f8f8f8;
              z-index: 999;
              overflow: hidden;
              transition: all 0.3s ease 0s;
              .entry_arrow img {
                right: 20px;
              }
            }
          }
          .txtBox {
            .expert {
              /* border-bottom: 1px #F0F0E1 solid; */
              margin: 0 0 10px 0;
              padding: 0 0 10px 0;
            }
            .date-cat {
              .date {
              }
              .cat {
                background: #f8b551;
                color: #fff;
                display: inline-block;
                padding: 3px 5px;
                margin-left: 10px;
              }
            }
          }
          a {
            display: block;
            color: #868686;
            text-decoration: none;
            padding: 10px 0px;
            box-sizing: border-box;
          }
          figure {
            overflow: hidden;
            width: 150px;
            height: 150px;
            float: left;
            margin: 0 15px 0 0;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
        .cat_outer {
          height: 60px;
          overflow: hidden;
        }
        .blog {
          background: #dfdfd0;
          color: #fff;
          line-height: 30px;
          padding: 10px 25px 6px;
        }

        .category {
          ul {
            padding: 10px 0;
            li {
              margin: 0 0 10px;
              padding: 0 0 10px 0;
              /* border-bottom: 1px #666 solid; */
              a {
                display: block;
                padding: 0 0 0 15px;
                height: 20px;
                line-height: 20px;
                color: #8c8c8c;
                text-decoration: none;
                margin-bottom: 20px;
              }
            }
          }
        }

        .tag {
          border: 1px solid #666;
          margin: 15px 0 10px;
          padding: 15px 0 5px 55px;
        }
        .tag02 {
          border: 1px solid #666;
          margin: 15px 0 10px;
          padding: 15px 0 5px 23px;
        }
      }
      .catename {
        padding-top: 5px;
        padding-right: 10px;
        padding-bottom: 5px;
        padding-left: 10px;
        background-color: #f8b551;
        color: #ffffff;
        display: inline-block;
        margin-left: 10px;
      }
      .recommend-main {
      }
      .recommend-kiji {
      }
      .related-posts {
        h2 {
          text-align: left;
          position: relative;
          font-size: 20px;
          color: #8c8c8c;
          padding-left: 20px;
          margin-bottom: 40px;
          &:after {
            content: "";
            background: #cfa972;
            display: block;
            width: 100%;
            height: 2px;
            border: solid 1px #eee;
            position: absolute;
            left: 0;
            bottom: -10px;
          }
        }
        ul {
          li {
            margin-bottom: 20px;
            a {
              display: flex;
              justify-content: left;
              align-items: flex-start;
              width: 100%;
              text-decoration: none;
              .thumb {
                width: 130px;
                height: 130px;
                overflow: hidden;
                img {
                  width: 100%;
                  height: auto;
                }
              }
              .detail {
                width: calc(100% - 80px);
                padding-left: 10px;
                display: block;
                p {
                  display: block;
                  width: 100%;
                }
                .title {
                  font-size: 20px;
                  margin-bottom: 10px;
                }
                .text {
                }
              }
            }
          }
        }
      }
      /* side */
      .side {
        margin-top: 30px;

        .about {
          margin: 0 0 20px;
        }
        .category,
        .pop {
          width: 100%;
          overflow: hidden;
          zoom: 1;
          margin: 0 0 50px 0;
        }
        h2 {
          text-align: left;
          padding: 0 0 5px 25px;
          position: relative;
          margin-bottom: 30px;
          border-bottom: solid 1px #eee;
        }
        .category {
          ul {
            padding: 10px 0;
            li {
              margin: 0 0 10px;
              padding: 0 0 10px 0;
              /* border-bottom: 1px #666 solid; */
              a {
                display: block;
                padding: 0 0 0 15px;
                height: 20px;
                line-height: 20px;
                color: #8c8c8c;
                text-decoration: none;
                margin-bottom: 20px;
              }
            }
          }
        }
        .pop {
          .popBox {
            /* border: 1px #DDDDDD solid; */
            padding: 20px 0px;
            ul {
              li {
                margin-bottom: 20px;
                a {
                  display: flex;
                  justify-content: left;
                  align-items: center;
                  width: 100%;
                  text-decoration: none;
                  .thumb {
                    width: 80px;
                    img {
                      width: 100%;
                      height: auto;
                    }
                  }
                  .detail {
                    width: calc(100% - 80px);
                    padding-left: 10px;
                    display: block;
                    p {
                      display: block;
                      width: 100%;
                    }
                    .cat {
                      color: #8d9056;
                    }
                    .date {
                      color: #868686;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // STAFF PAGE
    .staff-content-wrap {
      .staff-list {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: justify;
        justify-content: space-between;

        li {
          width: 50%;
          padding: 0 10px;
          box-sizing: border-box;
          margin-bottom: 20px;
          a {
            text-decoration: none;
            .photo {
              margin-bottom: 10px;
              img {
                width: 100%;
                height: auto;
              }
            }
            .name {
              margin-bottom: 15px;
              font-weight: bold;
              font-size: 20px;
            }
            .title {
              color: #ccc;
              margin-bottom: 10px;
            }
          }
        }
      }
      .shop-title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 30px;
      }

      .staff-main {
        .inner {
          .staff-detail {
            .staff-photo {
              width: 80%;
              margin: 0 auto 20px;
              img {
                width: 100%;
                height: auto;
              }
            }
            .detail-wrap {
              position: relative;
              width: 80%;
              margin: 0 auto;
              .name-reserve {
                margin-bottom: 20px;
                .name {
                  position: relative;
                  .section {
                    display: block;
                    margin-bottom: 15px;
                    color: #767676;
                  }
                  .main-name {
                    font-size: 22px;
                    font-weight: bold;
                    span {
                      display: inline-block;
                      font-size: 12px;
                      margin-left: 10px;
                    }
                  }
                }
              }
              .link-btn {
                display: block;
                position: absolute;
                width: 100%;
                height: 40px;
                line-height: 40px;
                background: #fff100;
                text-align: center;
                right: 0;
                top: 0;
                box-sizing: border-box;
                border-radius: 6px;
                font-weight: bold;
              }
              .details {
                margin-bottom: 30px;
                .staff-skill {
                  box-sizing: border-box;
                  padding-right: 10px;
                }
                .staff-message {
                  box-sizing: border-box;
                  /* padding-left:10px; */
                  color: #767676;
                }
              }
              .staff-instagram .staff-instagram-title {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 15px;
                i {
                  font-size: 27px;
                  display: inline-block;
                  margin-right: 10px;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }
}
